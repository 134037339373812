<template>
  <div>
    <div class="type-area">
      <crumbs :crumbs="crumbs"/>
      <div class="indexTitle">方案咨询</div>
      <div class="prompt">提交以下真实信息，我们将在一个工作日内联系并提供咨询服务</div>
      <div class="form">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">

          <el-form-item label="联系人" prop="name">
            <el-input v-model="form.name" maxlength="10"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="tel">
            <el-input type="tel" v-model="form.tel" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="公司名称">
            <el-input v-model="form.companyName"></el-input>
          </el-form-item>
          <el-form-item label="业务需求">
            <el-input class="desc" type="textarea" :autosize="{ minRows: 4 }" v-model="form.desc"></el-input>
          </el-form-item>
          <div class="portalCommitment">
            <span><img src="./images/solution/zixunshenqing.svg" style="width:20px;vertical-align: middle"/></span>
            <span class="commitment">奇点闪耀承诺：您的隐私将被严格保密</span>
          </div>
          <div class="btn">
            <el-button type="primary" @click="submitForm('ruleForm')" class="submit">提交</el-button>
          </div>

        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
  import { consultingAdd } from "@/api/request";
  import Crumbs from "./crumbs/Crumbs";

  export default {
    name: "contactus",
    components: { Crumbs },
    data() {
      //判断手机号码
      let rulesCheckTel = (rule, value, callback) => {
        if (!(/^1[3456789]\d{9}$/.test(value))) {
          return callback(new Error("手机号码格式有误，请重填"));
        } else {
          callback();
        }

      };
      return {
        crumbs: [
          { path: "/", title: "首页" },
          { path: "", title: "方案咨询" }
        ],
        form: {
          name: "",
          tel: "",
          companyName: "",
          desc: ""
        },
        rules: {
          name: [
            { required: true, message: "请输入联系人", trigger: "blur" }
          ],
          tel: [
            { required: true, message: "请输入手机号码", trigger: "blur" },
            { validator: rulesCheckTel, trigger: "blur" }
            // { min: 6, max: 11, message: '手机号码格式不正确', trigger: 'blur' }
          ]
        }
      };
    },

    methods: {
      submitForm(formName) {

        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log(this.form);
            let obj = {
              companyName: this.form.companyName,
              contactName: this.form.name,
              createBy: "",
              createTime: "",
              id: "",
              needDesc: this.form.desc,
              tel: this.form.tel,
              updateBy: "",
              updateTime: ""
            };
            // console.log(obj);

            consultingAdd(obj).then(res => {
              if (res.code == 0 || res.code == 200) {
                this.$refs[formName].resetFields();// 重置表单
                this.form.companyName = "";
                this.form.desc = "";
                this.$message({
                  title: "成功",
                  message: "提交成功，我们将尽快与您联系！",
                  type: "success"
                });
                this.$router.push({path:'/'})
              } else {
                this.$message.error(res.message);
              }
            });

          } else {
            console.log("error submit!!");
            return false;
          }

        });
      },
      // 重置表单
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }


  };
</script>

<style lang="less" scoped>
  /*/deep/ .right{*/
    /*right: 165px !important;*/
  /*}*/
  /*.el-message .el-icon-success {*/
    /*color: #0F80DC;*/


  /*}*/
  /*.el-message--success{*/
    /*background: #ebeff9;*/
    /*border-color: #ebeff9;*/
  /*}*/


  .indexTitle {
    margin: 30px auto 24px auto;
  }
  /* 按钮边框颜色 */
  /deep/ .el-button--primary{
    border-color: #0F80DC;
  }
  /deep/ input[type=text]:focus{
    border-color: #0F80DC;
  }
  /deep/ textarea:focus{
    border-color: #0F80DC;
  }

  .prompt {
    height: 14px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    text-align: center;
    color: #5c6066;
    line-height: 14px;
    margin-bottom: 16px;
  }

  .form {
    width: 50%;
    margin: 0 auto;
  }

  .el-form-item {
    margin: 24px auto 0px auto;
  }

  .portalCommitment {
    padding-left: 100px;
    margin: 11px auto 27px auto;
  }

  .commitment {
    margin-left: 8px;
    height: 12px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    text-align: center;
    color: #8d9299;
    line-height: 12px;
  }

  .btn {
    text-align: center;
    margin-bottom: 100px;
  }

  .submit {
    width: 180px;
    height: 40px;
    background: #0F80DC;
    color: #fff;
  }

  .submit:hover {
    background: #1a6dd4;
    border-color: #1a6dd4;
  }
</style>
